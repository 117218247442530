import { SEASON_PREDICTIONS } from "./routes";

export const useFirebaseEmulator = false;

export const isRankingsLocked = false;
export const isSeasonPredictionsLocked = false;

export const isWeeklyPredictionsLocked = false;

export const currentSeason = "jenn_2024";
export const defaultSeason = "jenn_2024";
export const currentWeek = 2;
export const currentRoseCeremony = 1;

export const myUserEntry = "myUserEntry";

export const rankings = "RANKINGS";
export const seasonPredictions = "SEASON_PREDICTIONS";
export const setupSteps = [rankings, SEASON_PREDICTIONS];